import React, { Component } from "react";
import { graphql } from "gatsby";
import styled from "styled-components";
import Seo from "../components/Seo/Seo";

const ContactFormContainer = styled.form`
  max-width: 300px;
  margin: 0;
`;

const Field = styled.div`
  position: relative;
  padding-top: 1em;
  margin: 2em 0;

  input,
  textarea {
    background: transparent;
    border: none;
    border-radius: 0;
    border-bottom: 2px solid black;
    width: 100%;
    font-family: futura-pt-bold, sans-serif;
    font-size: 1rem;
    font-weight: bold;
    letter-spacing: 2.5px;
    text-transform: uppercase;
    outline: none;
    appearance: none;

    transition: border-color 500ms ease-out;
    l ::placeholder {
      color: rgba(0, 0, 0, 0.5);
    }
  }

  &.filled input:invalid,
  &.filled input:invalid {
    border-bottom: 2px solid red;
  }

  input:focus + label,
  textarea: focus + label,
  &.filled label {
    color: rgba(0, 0, 0, 0.6);
    transform: translate3d(0, -1.25em, 0) scale3d(0.75, 0.75, 1);
  }

  textarea {
    max-width: 300px;
    min-height: 80px;
    margin-bottom: -7px;
  }
`;

const Label = styled.label`
  font-family: futura-pt-bold, sans-serif;
  font-size: 0.875rem;
  letter-spacing: 2.5px;
  text-align: left;
  text-transform: uppercase;
  pointer-events: none;
  color: rgba(0, 0, 0, 0.3);
  display: block;
  padding: 0;
  position: absolute;
  top: 0.95em;
  left: 0;

  transform-origin: 0 0;
  transition: transform 150ms, color 1s;
  transition-timing-function: ease-out;
`;

const Submit = styled.button`
  font-family: futura-pt-bold, sans-serif;
  font-size: 12px;
  text-transform: uppercase;
  letter-spacing: 2.5px;
  background: black;
  color: white;
  border: none;
  border-radius: 4px;
  height: 34px;
  text-align: left;
  padding: 0 10px 0 10px;
  cursor: pointer;
`;

const Sucess = styled.p``;

class Textarea extends Component {
  constructor(props) {
    super(props);

    this.state = {
      value: null,
    };
  }

  onFocus(e) {
    this.setState({
      value: e.target.value,
    });
  }

  onBlur(e) {
    this.setState({
      value: e.target.value,
    });
  }

  isInputFilled() {
    return this.state.value ? "filled" : "";
  }

  render() {
    return (
      <Field className={this.isInputFilled()}>
        <textarea
          {...this.props}
          onFocus={this.onFocus.bind(this)}
          onBlur={this.onBlur.bind(this)}
        />
        <Label>{this.props.label}</Label>
      </Field>
    );
  }
}

class Input extends Component {
  constructor(props) {
    super(props);

    this.state = {
      value: null,
    };
  }

  onFocus(e) {
    this.setState({
      value: e.target.value,
    });
  }

  onBlur(e) {
    this.setState({
      value: e.target.value,
    });
  }

  isInputFilled() {
    return this.state.value ? "filled" : "";
  }

  render() {
    return (
      <Field className={this.isInputFilled()}>
        <input
          {...this.props}
          onFocus={this.onFocus.bind(this)}
          onBlur={this.onBlur.bind(this)}
        />
        <Label>{this.props.label}</Label>
      </Field>
    );
  }
}

const encode = (data) => {
  return Object.keys(data)
    .map((key) => encodeURIComponent(key) + "=" + encodeURIComponent(data[key]))
    .join("&");
};

class ContactForm extends Component {
  constructor(props) {
    super(props);
    this.state = {
      name: "",
      email: "",
      subject: "",
      message: "",
      sent: false,
    };
  }

  handleChange = (e) => {
    this.setState({ [e.target.name]: e.target.value });
  };

  handleSubmit = (e) => {
    fetch("/", {
      method: "POST",
      headers: { "Content-Type": "application/x-www-form-urlencoded" },
      body: encode({ "form-name": "contact", ...this.state }),
    })
      .then(() => {
        this.setState({ sent: true });
      })
      .catch((error) => alert(error));

    e.preventDefault();
  };

  render() {
    const { name, email, subject, message } = this.state;
    var contactForm;

    if (this.state.sent) {
      contactForm = <Sucess>Thank you!</Sucess>;
    } else {
      contactForm = (
        <ContactFormContainer onSubmit={this.handleSubmit}>
          <Input
            label="Name"
            name="name"
            type="text"
            required
            value={name}
            onChange={this.handleChange}
          />

          <Input
            label="Email"
            name="email"
            type="email"
            required
            value={email}
            onChange={this.handleChange}
          />

          <Input
            label="Betreff"
            name="subject"
            type="text"
            required
            value={subject}
            onChange={this.handleChange}
          />

          <Textarea
            label="Nachricht"
            name="message"
            required
            value={message}
            onChange={this.handleChange}
          />

          <Submit type="submit">Abschicken</Submit>
        </ContactFormContainer>
      );
    }

    return <div>{contactForm}</div>;
  }
}

const ContactPage = ({ data }) => {
  const text = data.contentfulPage.text || "";
  return (
    <div>
      <Seo title="Contact" />
      <div
        dangerouslySetInnerHTML={{ __html: text.childMarkdownRemark.html }}
      />
      <ContactForm />
    </div>
  );
};
export default ContactPage;

export const query = graphql`
  query ContactQuery {
    contentfulPage(slug: { eq: "kontakt" }) {
      id
      title
      slug
      text {
        childMarkdownRemark {
          html
        }
      }
    }
  }
`;
